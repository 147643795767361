<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="row ml-0 mt-3 mb-3 mr-0">
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <select class="form-control" :class="{'mb-3': platform == 'mobile'}" v-model="status">
                            <option value="sim" :selected="status == 'sim'">Pagos</option>
                            <option value="nao" :selected="status == 'nao'">Pendentes</option>
                        </select>
                    </div><!-- /col -->
                    <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                        <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="text" v-model="search" placeholder="Pesquisar cliente...">
                    </div><!-- /col -->
                    <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <div class="input-group-text" style="width:63.84px">Data</div>
                            </div>
                            <input class="form-control" type="date" v-model="date">
                        </div>
                    </div><!-- /col -->
                </div><!-- /row -->
                <div class="mt-3 mb-3">
                    <div v-show="!loading && error" class="alert alert-danger" role="alert">
                        <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                        <hr />
                        <p class="mb-0">{{error}}</p>
                    </div><!-- /alert-danger -->
                    <div class="card mb-3" v-for="(item, index) in filteredListTransactions" :key="index">
                        <div class="card-header">
                            <div class="row">
                                <div class="col mt-1 text-truncate">{{item.nome}}</div>
                                <div class="col-auto pr-0">
                                    <button class="btn btn-block btn-sm btn-info pl-3 pr-3" @click="viewInfo(item)">
                                       <i class="fas fa-eye"></i>
                                    </button>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-sm btn-block btn-success" :class="{'pl-3 pr-3': platform == 'mobile'}" :disabled="item.pago == 'sim'" @click="pay(item._id)">
                                        <template v-if="platform == 'desktop'">
                                            <i class="fas fa-check mr-2"></i>Pagar
                                        </template>
                                        <template v-else>
                                            <i class="fas fa-check"></i>
                                        </template>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row border-bottom pb-2">
                                <div class="col text-truncate">Nome</div>
                                <div class="col text-truncate">{{item.nome}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">Usuário</div>
                                <div class="col text-truncate text-danger">{{item.usuario}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">CPF</div>
                                <div class="col text-truncate">{{item.cpf}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">Contato</div>
                                <div class="col text-truncate">{{item.contato}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">Valor do saque</div>
                                <div class="col text-truncate">{{item.valor}}</div>
                            </div><!-- /row -->
                            <div class="row border-bottom pt-2 pb-2">
                                <div class="col text-truncate">Situação do saque</div>
                                <div class="col text-truncate" :class="{'text-danger': item.pago == 'nao', 'text-success': item.pago == 'sim'}">{{item.pago == 'sim' ? 'Pago' : 'Pendente'}}</div>
                            </div><!-- /row -->
                            <div class="row pt-2">
                                <div class="col text-truncate">Horário da operação</div>
                                <div class="col text-truncate">{{item.horario | formatDate}}</div>
                            </div><!-- /row -->
                        </div><!-- /card-body -->
                    </div><!-- /card -->
                </div>
            </div>
        </div>
      <Loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import { PIX } from 'gpix/dist'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            date: '',
            error: '',
            search: '',
            status: 'sim',
            loading: false,
            transactions: [],
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
				},
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            }
        }
    },
    components: {
        Navbar,
        Loading
    },
    methods: {
        pay(id) {

            const self = this;

            try {

                const user = self.transactions.find((item) => item._id == id);

                Swal.fire({
                    title: 'Confirmar pagamento',
                    html: `Você deseja mesmo confirmar o pagamento <br /> do cliente "<b>${user.nome}</b>" ? <p class="mt-3 pt-3 border-top">Valor da transação: <b>${user.valor}</b>`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.loading = true;

                        api.put(`dashboard/confirm-pay/${id}`).then((response) => {
                            switch(response.data.result) {
                                case 'success':

                                    self.updateTransactions(id);

                                    Swal.fire(
                                        'Parabéns!',
                                        'O pagamento foi confirmado!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha!',
                                        response.data.message,
                                        'error'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha!',
                                    self.network_erros[error.status]['message'],
                                    'warning'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha!',
                                    self.network_erros[408]['message'],
                                    'warning'
                                );
                            }
                        }).finally(() => {
                            self.loading = false;
                        });
                    }
                });
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção!',
                    'Ocorreu um erro, tente novamente!',
                    'error'
                );
            }
        },
        async viewInfo(data) {

            try {

                const self = this;

                Swal.fire({
                    title: 'Dados bancários',
                    html: `
                        <div class="row bg-ddd rounded-top">
                            <div class="col text-truncate pt-2 pb-2">Nome</div>
                            <div class="col text-truncate pt-2 pb-2">${data.nome}</div>
                        </div>
                        <div class="row bg-f1f1f1">
                            <div class="col text-truncate pt-2 pb-2">CPF</div>
                            <div class="col text-truncate pt-2 pb-2">${data.cpf}</div>
                        </div>
                        <div class="row bg-ddd">
                            <div class="col text-truncate pt-2 pb-2">Banco</div>
                            <div class="col text-truncate pt-2 pb-2">${data.dados_bancarios.banco}</div>
                        </div>
                        <div class="row bg-f1f1f1">
                            <div class="col text-truncate pt-2 pb-2">Agência</div>
                            <div class="col text-truncate pt-2 pb-2">${data.dados_bancarios.agencia}</div>
                        </div>
                        <div class="row bg-ddd">
                            <div class="col text-truncate pt-2 pb-2">Conta</div>
                            <div class="col text-truncate pt-2 pb-2">${data.dados_bancarios.conta}-${data.dados_bancarios.digito}</div>
                        </div>
                        <div class="row bg-f1f1f1 rounded-bottom">
                            <div class="col text-truncate pt-2 pb-2">Tipo de conta</div>
                            <div class="col text-truncate pt-2 pb-2">${data.dados_bancarios.tipo}</div>
                        </div>
                        <div class="text-center mt-3 mb-3">
                            <img src="/images/pix-bc-logo-4.png" width="200" border="0" alt="PIX">
                        </div>
                        <div class="row bg-ddd rounded-top">
                            <div class="col text-truncate pt-2 pb-2">Tipo de chave pix</div>
                            <div class="col text-truncate pt-2 pb-2">Chave pix</div>
                        </div>
                        <div class="row bg-f1f1f1 rounded-bottom">
                            <div class="col text-truncate pt-2 pb-2">${self.pixType(data.dados_bancarios.pix_tipo)}</div>
                            <div class="col text-truncate pt-2 pb-2">${data.dados_bancarios.pix ? data.dados_bancarios.pix : 'Sem chave pix'}</div>
                        </div>
                    `,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Fechar',
                    confirmButtonText: 'Gerar QR Code'
                }).then(async function(result) {

                    if (result.value) {

                        if (data.dados_bancarios.pix) {
                            await self.generateQRCode(data);
                        } else {
                            Swal.fire(
                                'Atenção!',
                                `O cliente <strong>${data.nome}</strong> não tem nenhuma chave pix cadastrada!`,
                                'error'
                            );
                        }
                    }
                });
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção!',
                    'Ocorreu um erro, tente novamente!',
                    'error'
                );
            }
        },
        async generateQRCode(data) {

            try {

                const cidade = data.endereco.split('-')[1];
                const valor = data.valor.toString().replace(',','.');
                const chave = data.dados_bancarios.pix_tipo == 'Celular' ? `+55${data.dados_bancarios.pix}` : data.dados_bancarios.pix;
                const criaPix = PIX.static().setReceiverName(data.nome.toString().trim()).setReceiverCity(cidade.toString().trim()).setKey(chave.toString()).setIdentificator(data._id).setAmount(parseFloat(valor));

                if (this.platform == 'desktop') {
                    Swal.fire(
                        'QR Code',
                        `
                            <p class="mb-0">Aponte a câmera para realizar o pagamento.</p>
                            <p>Após realizar o pagamento, aperte na opção "<span class="text-success">Pagar</span>".</p>
                            <img src="${await criaPix.getQRCode()}" width="228" heigth="228" border="0" alt="QR Code">
                        `,
                        'success'
                    );
                } else {
                    Swal.fire(
                        'QR Code',
                        `
                            <p class="mb-0">Copie e cole no app do seu banco.</p>
                            <p>Após realizar o pagamento, aperte na opção "<span class="text-success">Pagar</span>".</p>
                            <input class="swal2-input w-100" id="input-pix" type="text" onclick="window.copyClipboard();" value="${criaPix.getBRCode()}" readonly>
                        `,
                        'success'
                    );
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Atenção!',
                    'Ocorreu um erro ao gerar o qr-code, tente novamente!',
                    'error'
                );
            }
        },
        searchTransactions() {

            const self = this;

            if (!self.date) return;

            self.error = '';
            self.loading = true;
            self.transactions = [];

            api.get(`dashboard/transactions/${self.status}/${self.date}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.transactions = response.data.transactions.sort((a, b) => {

                            if (a.horario > b.horario) return -1;
                            if (a.horario < b.horario) return 1;

                            return 0;
                        });
                    break;
                    default:
                        self.error = response.data.message;
                    break
                }
            }).catch((error) => {
                try {
                    self.error = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.error = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        updateTransactions(id) {

            try {

                const index = this.transactions.findIndex((item) => item._id == id);

                this.transactions[index].pago = 'sim';
            } catch(e) {
                //
            }
        }
    },
    watch: {
        date() {
            this.searchTransactions();
        },
        status() {
            this.searchTransactions();
        }
    },
    filters: {
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY HH:mm');
        }
    },
    computed: {
        filteredListTransactions() {
            return this.transactions.filter((item) => {
                return item.nome.toLowerCase().trim().includes(this.search.toLowerCase().trim()) || item.usuario.toLowerCase().includes(this.search.toLowerCase().trim())
            });
        }
    },
    mounted() {
        window.copyClipboard = () => {

            var copyText = document.getElementById('input-pix');

            if (copyText) {
                navigator.clipboard.writeText(copyText.value).then(() => {
                    Swal.fire(
                        'Parabéns!',
                        'O <strong>QR Code</strong> foi copiado com sucesso!',
                        'success'
                    )
                }, () => {
                    Swal.fire(
                        'Erro!',
                        'Não foi possível copiar o <strong>QR Code</strong>!',
                        'error'
                    )
                }); 
            } 
        }
    },
    created() {
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
</style>