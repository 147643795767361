<template>
    <div :class="{'p-2': alert.show}">
        <div v-if="alert.show" class="d-flex flex-column justify-content-center align-items-center">
            <div class="text-center">
                <img src="https://uploaddeimagens.com.br/images/002/663/756/full/oops.png?1590075514" alt="OOPS!">
            </div>
            <div class="alert alert-dark" role="alert">
                <span>{{alert.message}}</span>
                <hr />
                <div class="row">
                    <div class="col pr-0">
                        <input class="form-control" type="text" placeholder="Código" v-model="code" maxlength="8">
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-warning btn-block" @click="ticketByCode(code)">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-html="template"></div>
        <div v-if="!loading && !alert.show" class="w-100 text-center">
            <a class="btn btn-block btn-danger rounded-0 text-white" :href="service_link">
                <i class="fas fa-headset mr-2"></i>Atendimento
            </a>
        </div>
        <div v-if="message" class="p-3 text-center">
            <p class="text-warning">Fale com a gente clicando em "<strong>Atendimento</strong>".</p>
            <span class="text-white" v-html="message"></span>
        </div>
        <div v-if="commission != 0" class="p-3 text-center bg-secondary">
            <span class="text-white">O vendedor receberá <strong>{{commission}}%</strong> referente a premiação!</span>
            <hr />
            <span class="text-white">Ao apostar em nosso site, você está concordando com nossas <strong>regras</strong>.</span>
        </div>
        <nav class="navbar sticky-bottom navbar-light bg-dark" v-show="!alert.show">
            <div class="row w-100">
                <div class="col text-right pr-0">
                    <small class="text-white">Desenvolvido por</small>
                </div>
                <div class="col text-left pl-0">
                    <a href="https://betsnow.net">
                        <img src="https://demo.betsnow.net/images/logo.png" height="30" alt="BetsNOW">
                    </a>
                </div>
            </div>
        </nav>
      <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import {api} from '../api'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            code: '',
            bets: [],
            message: '',
            template: '',
            commission: 0,
            loading: false,
            alert: {
                show: false,
                message: ''
            },
            network_erros: {
				0: {
					message: 'Não há conexão com a internet!'
                },
                401: {
                    message: 'Você não tem autorização para realizar essa operação!'
                },
				404: {
					message: 'Página solicitada não foi encontrada!'
				},
				500: {
					message: 'O servidor está passando por problemas técnicos!'
				},
				408: {
					message: 'Por favor, verifique sua conexão de rede e tente novamente!'
				}					
            }
        }
    },
    components: {
        Loading
    },
    methods: {
        ticketByCode(code) {

            const self = this;

            if (!code) return;

            self.code = code;
            self.loading = true;
            self.alert.show = false;

            api.get(`bet/anonymous/${code}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.bets = response.data.bets;
                        self.commission = response.data.comissao_aposta_premiada;
                        self.message = response.data.message.replace(/\n/g, "<br />");
                        self.viewBet(response.data.id);
                    break;
                    case 'nothing_found':
                        self.loading = false;
                        self.alert.show = true;
                        self.alert.message = 'Não foi possível encontrar a aposta. Por favor, verifique o código e tente novamente!';
                    break;
                    default:
                        self.loading = false;
                        self.alert.show = true;
                        self.alert.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.loading = false;
                    self.alert.show = true;
                    self.alert.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.loading = false;
                    self.alert.show = true;
                    self.alert.message = self.network_erros[408]['message']
                }
            });
        },
        viewBet(id) {

            const self = this;

            api.get(`view-bet/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.mountView(id, response.data.hunches);
                    break;
                    default:
                        self.alert.show = true;
                        self.alert.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.alert.show = true;
                    self.alert.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.alert.show = true;
                    self.alert.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        mountView(id, hunches) {
            
            let html = ``;

            const index = this.bets.findIndex((item) => item.id == id);

            try {

                if (index != -1) {
                    
                    let anteior_value = 0;
                    let bet = this.bets[index];
                    let hunches_order = this.orderBySchedule(hunches);
                    let commission = (bet.premium / 100) * this.commission;
                    
                    for (let key in hunches_order) {

                        let total_cards = 0;
                        let total_corners = 0;
                        
                        let hun = hunches_order[key];
                        let league = hun.sport == '9' ? 'Acumuladão' : hun.league;

                        if (hun.statistics instanceof Object) {

                            if (Object.keys(hun.statistics.cartoes).length > 0) {
                                total_cards = hun.statistics.cartoes.total;
                            }

                            if (Object.keys(hun.statistics.escanteios).length > 0) {
                                total_corners = hun.statistics.escanteios.total;
                            }
                        }

                        if (anteior_value != league) {
                            html += `
                                <div class="ticket-row-item ticket-header">
                                    <div class="ticket-col-item text-left">${league}</div>
                                    <div class="ticket-col-item text-right">${hun.live ? 'Ao vivo' : hun.sport == '8' ? 'Desafio' : 'Pré-jogo'}</div>
                                </div>                            
                            `;
                        }

                        html += `
                            <div class="ticket-row-item">
                                <div class="ticket-col-item text-left">${hun.team_home}</div>
                                <div class="ticket-col-item text-right">${this.convertDateTicket(hun.schedule)}</div>
                            </div>
                            <div class="ticket-row-item">
                                <div class="ticket-col-item text-left">${hun.team_away}</div>
                                <div class="ticket-col-item text-right">${hun.accumulated ? '' : 'R$ ' + hun.odd}</div>
                            </div>
                            <div class="ticket-row-item border-top pt-2 mt-2">
                                <div class="ticket-col-item text-left">Modalidade</div>
                                <div class="ticket-col-item text-right">${this.findModality(hun.sport)}</div>
                            </div>
                            <div class="ticket-row-item">
                                <div class="ticket-col-item text-left">Situação</div>
                                <div class="ticket-col-item text-right ${hun.situation == 1 ? 'text-success' : hun.situation == 2 ? 'text-danger' : hun.situation == 3 ? 'text-primary' : hun.situation == 4 ? 'text-warning' : ''}">${this.findSituation(hun.situation)}</div>
                            </div>
                            <div class="ticket-row-item">
                                <div class="ticket-col-item text-left">Ao vivo</div>
                                <div class="ticket-col-item text-right">${hun.live ? 'Sim' : 'Não'}</div>
                            </div>
                            ${hun.sport == 1 && (hun.situation == 1 || hun.situation == 2) ? `
                            <div class="ticket-row-item border-top mt-2">
                                <div id="carouselTicketControls-${key}" class="carousel slide w-100" data-ride="carousel">
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div class="row w-100 m-0">
                                                <div class="col-12 text-center text-warning pt-2">Resultado</div>
                                                <div class="w-100"></div>
                                                <div class="col text-center pt-2">90</div>
                                                <div class="col text-center pt-2">1T</div>
                                                <div class="col text-center pt-2">2T</div>
                                                <div class="w-100"></div>
                                                <div class="col text-center text-warning">${hun.scoreboards.ft}</div>
                                                <div class="col text-center text-warning">${hun.scoreboards.pt}</div>
                                                <div class="col text-center text-warning">${hun.scoreboards.st}</div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div class="row w-100 m-0">
                                                <div class="col-12 text-center text-warning pt-2">Estatísticas</div>
                                                <div class="w-100"></div>
                                                <div class="col text-center pt-2">Escanteios</div>
                                                <div class="col text-center pt-2">Cartões</div>
                                                <div class="w-100"></div>
                                                <div class="col text-center text-warning">${total_corners}</div>
                                                <div class="col text-center text-warning">${total_cards}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev w-auto" href="#carouselTicketControls-${key}" role="button" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next w-auto" href="#carouselTicketControls-${key}" role="button" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                            ` : hun.sport != 1 && hun.sport != 3 && hun.sport != 8 && (hun.situation == 1 || hun.situation == 2) ? `
                            <div class="ticket-row-item border-top mt-2">
                                <div class="row w-100 m-0">
                                    <div class="col-12 text-center text-warning pt-2">Resultado</div>
                                    <div class="w-100"></div>
                                    <div class="col text-center pt-2">Final</div>
                                    <div class="w-100"></div>
                                    <div class="col text-center text-warning">${hun.scoreboards.ft}</div>
                                </div>
                            </div>
                            ` : ''}
                            <div class="ticket-row-item ticket-option">
                                <div class="w-100 text-center">${hun.market}</div>
                            </div>
                        `;

                        anteior_value = league;
                    }

                    this.template = `
                        <div class="ticket">
                            <div class="logo">
                                <a href="/"><img src="` + this.logo + `" width="200" /></a>
                            </div><!-- /logo -->
                            ${bet.situation == 1 ? `
                            <div class="row mb-3">
                                <div class="col">
                                    <a class="btn btn-block btn-danger text-white" href="/streaming/${this.code}">
                                        <span class="blink-animation"><i class="fas fa-signal-stream mr-2"></i>Acompanhe ao vivo</span>
                                    </a>
                                </div>
                            </div>
                            ` :  `
                            <div class="alert text-center mb-3 ${bet.situation == 2 ? 'alert-success' : bet.situation == 3 ? 'alert-danger' : bet.situation == 4 ? 'alert-secondary' : 'hide'}">
                                ${bet.situation == 2 ? '<i class="fas fa-trophy mr-2"></i>Aposta premiada' : bet.situation == 3 ? '<i class="fas fa-sad-tear mr-2"></i>Aposta perdida' : bet.situation == 4 ? '<i class="fas fa-ban mr-2"></i>Aposta cancelada' : 'Sem informações'}
                            </div>`}
                            <div class="ticket-row rounded-top">
                                <div class="ticket-col text-left">Código</div>
                                <div class="ticket-col text-right text-uppercase">${bet.code}</div>
                            </div><!-- /ticket-row -->
                            <div class="ticket-row">
                                <div class="ticket-col text-left">Vendedor</div>
                                <div class="ticket-col text-right">${bet.salesman}</div>
                            </div><!-- /ticket-row -->
                            <div class="ticket-row">
                                <div class="ticket-col text-left">Apostador</div>
                                <div class="ticket-col text-right">${bet.punter}</div>
                            </div><!-- /ticket-row -->
                            <div class="ticket-row">
                                <div class="ticket-col text-left">Valor apostado</div>
                                <div class="ticket-col text-right">R$ ${this.formatCoin(bet.value)}</div>
                            </div><!-- /ticket-row -->
                            <div class="ticket-row">
                                <div class="ticket-col text-left">Possível retorno</div>
                                <div class="ticket-col text-right">R$ ${this.formatCoin(bet.premium)}</div>
                            </div><!-- /ticket-row -->

                            ${this.commission != 0 ? `
                                <div class="ticket-row">
                                    <div class="ticket-col text-left">Vendedor paga</div>
                                    <div class="ticket-col text-right">R$ ${this.formatCoin(bet.premium - commission)}</div>
                                </div><!-- /ticket-row -->
                            ` : ''}

                            <div class="ticket-row rounded-bottom">
                                <div class="ticket-col text-left">Horário da aposta</div>
                                <div class="ticket-col text-right">${[this.convertDate(bet.date), bet.hour].join(' - ')}</div>
                            </div><!-- /ticket-row -->
                            <div class="ticket-separator">
                                <h3 class="text-center">Palpites (${bet.total_hunches})</h3>
                            </div>
                            <div class="ticket-content">
                                ${html}
                            </div>
                        </div>                 
                    `;
                }
                else
                {
                    this.alert.show = true;
                    this.alert.message = 'Não foi possível abrir essa aposta, atualize a página e tente novamente!';
                }
            } 
            catch(e) 
            {
                this.alert.show = true;
                this.alert.message = 'Não foi possível abrir essa aposta, atualize a página e tente novamente!';
            }
        },
        orderBySchedule(obj) {
            return Object.values(obj).map(item => {
                return item
            }).sort((a, b) => {

                if (this.removeAccents(a.league) < this.removeAccents(b.league)) return -1;
                if (this.removeAccents(a.league) > this.removeAccents(b.league)) return 1;

                if (a.schedule > b.schedule) return 1;
                if (a.schedule < b.schedule) return -1;

                return 0;
            });
        },
        convertDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY');
        },
        convertDateTicket(date) {
            return moment(date).tz('America/Recife').format('DD/MM - HH:mm');
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
    },
    watch: {
        code(value) {
            this.code = value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        },
    },
    beforeMount() {
        this.loading = true;
    },
    created() {
        this.ticketByCode(this.$route.params.code);
    }
}
</script>

<style scoped>
img {
    max-width: 100%;
}
</style>